import React, { useContext, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { RootStoreContext } from './global/storeContext';
import { observer } from 'mobx-react-lite';
import routes, { IRoute, paths } from './routes';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { Loader } from './components/loader/loader';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useQuery } from './utils/route';
import { NotFoundPage } from './pages/errors/404';

const AuthGate: React.FC<{ children?: React.ReactNode }> = observer(({ children }) => {
    const { userStore, uiStore, walletStore } = useContext(RootStoreContext);

    useEffect(() => {
        if (!userStore.isAuthenticated) {
            uiStore.goTo(paths.login, window.location.href);
        }
        walletStore.getWallet().then(async (wallet) => {
            if (!!wallet) {
                const isPositiveTransactionInLast30Days =
                    await walletStore.isPositiveTransactionInLast30Days();
                console.log(wallet.amount < 0 && !isPositiveTransactionInLast30Days);
                if (wallet.amount < 0 && !isPositiveTransactionInLast30Days) {
                    uiStore.goTo(paths.account.financials);
                }
            }
        });
    }, [userStore, userStore.userId, uiStore]);

    return <>{children}</>;
});

const NoAuthGate: React.FC<{ children?: React.ReactNode }> = observer(({ children }) => {
    const { userStore, uiStore } = useContext(RootStoreContext);
    const q = useQuery();
    const location = useLocation();

    useEffect(() => {
        if (userStore.isAuthenticated) {
            const redirectURL = q.get('redirectURL');
            if (!!redirectURL) {
                uiStore.goTo(redirectURL);
            } else {
                uiStore.goTo(`${paths.home}${location.search}`);
            }
        }
    }, [userStore, userStore.userId, uiStore]);

    return <>{children}</>;
});

const App: React.FC = observer(() => {
    const { uiStore, userStore, analyticsStore } = useContext(RootStoreContext);
    const location = useLocation();
    const ldClient = useLDClient();
    const query = useQuery();

    const alertMsg = () => {
        const msg = uiStore.toastrMsg?.message ?? '';
        return <div dangerouslySetInnerHTML={{ __html: msg }} />;
    };

    useEffect(() => {
        if (query.has('closetab') && query.get('closetab') == 'true') {
            setTimeout(function () {
                console.log('>>> Closing Window <<<');
                uiStore.closeWindow();
            }, 2000);
        }
    });

    useEffect(() => {
        // track location changes
        analyticsStore.trackPage(location.pathname);
    }, [location]);

    useEffect(() => {
        const identify = async () => {
            await ldClient?.waitForInitialization();
            const ctx = { anonymous: userStore.userId ? false : true, key: userStore.userId };
            await ldClient?.identify(ctx, undefined, () => {
                console.log('>>> LD Client Context updated');
            });
        };
        identify();
    }, [userStore.isAuthReady, userStore.userId]);

    return (
        <>
            <div className='App'>
                <Loader loading={uiStore.loading} />

                <Routes>
                    {routes.map((prop: IRoute, key) => {
                        const path = `${prop.path}`;
                        return GetRouteComponent(
                            key,
                            path,
                            prop.authGuard,
                            prop.noAuthGuard,
                            prop.component,
                        );
                    })}
                    {GetRouteComponent('', '*', false, false, NotFoundPage)}
                </Routes>
                <Snackbar
                    open={uiStore.toastrOpen}
                    autoHideDuration={6000}
                    onClose={uiStore.closeToastr}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <MuiAlert
                        elevation={6}
                        variant='filled'
                        onClose={uiStore.closeToastr}
                        severity={uiStore.toastrMsg?.severity ?? 'info'}
                    >
                        {alertMsg()}
                    </MuiAlert>
                </Snackbar>
            </div>
        </>
    );
});

const GetRouteComponent = (
    key: any,
    path: string,
    authGuard: boolean,
    noAuthGuard: boolean,
    Component: React.FC,
) => {
    let route = <Route path={path} element={<Component />} key={key}></Route>;
    if (authGuard) {
        route = (
            <Route
                path={path}
                key={key}
                element={
                    <AuthGate>
                        <Component />
                    </AuthGate>
                }
            ></Route>
        );
    } else if (noAuthGuard) {
        route = (
            <Route
                path={path}
                key={key}
                element={
                    <NoAuthGate>
                        <Component />
                    </NoAuthGate>
                }
            ></Route>
        );
    }

    return route;
};

export default App;
